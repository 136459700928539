@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header {
    background: $bodyBackground;
  }

  &-content {
    border-radius: 0%;
  }

  .#{$prefix}-sider, .#{$prefix}-sider .#{$antPrefix}-menu {
    background: $cambridge;
  }
}
