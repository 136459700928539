@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label>label {
    color: $textColor;
    white-space: normal;
  }

  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }

  &-item {
    margin-bottom: 10px;
  }
}

.#{$prefix}-vertical {
  .#{$prefix}-item-label {
    padding: 0 0 4px;
  }
}

.supervision-address-form {
  .#{$prefix}-item-row {
    .#{$prefix}-item-control:first-child {
      transform: translateX(100%);
    }
  }
  
  .environment-btn-row {
    width: 300%;

    .environment-btn-wrapper {
      align-items: center;
    }
  }
}