@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  border-radius: 4px;
  box-shadow: unset;

  &-bordered {
    box-shadow: 2px 2px 5px 0px rgba(217, 186, 156, 0.50);
  }

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border-bottom: none;
  }
  &-bordered {
    border-color: $borderColor;
  }

  .#{$prefix}-head {
    min-height: unset;
    padding: 16px 16px 0;

    .#{$prefix}-head-wrapper {
      .#{$prefix}-head-title {
        flex: unset;
        border-bottom: 1px solid $primaryColor;
        color: $dimGray;
      }

      .#{$prefix}-head-title, .#{$prefix}-extra {
        padding-bottom: 8px;
      }
    }
  }

  .#{$prefix}-body {
    padding: 16px;

    .#{$antPrefix}-layout-content {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.#{$prefix}.card-with-tabs {
  .#{$prefix}-head {
    padding: 4px 16px 0;

    .#{$prefix}-head-wrapper .#{$prefix}-head-title {
      border: 0;
    }
  }
}

.#{$prefix}.dashboard-card {
  .#{$prefix}-head {
    .#{$prefix}-head-wrapper {
      position: relative;
      justify-content: center;

      .#{$prefix}-extra {
        position: absolute;
        right: 0;

        .#{$antPrefix}-badge {
          .#{$antPrefix}-badge-count {
            background: $primaryColor;
            font-size: 14px;
            height: 24px;
            min-width: 24px;
            line-height: 25px;
            border-radius: 24px;
          }
        }
      }
    }
  }

  .#{$prefix}-body {
    .#{$antPrefix}-list.alert-list {
      .#{$antPrefix}-col {
        > a > .#{$antPrefix}-row {
          .#{$antPrefix}-list-item {
            padding: 0;
            font-weight: 600;

            .#{$antPrefix}-badge-status-text {
              color: $dimGray;
            }
          }

          .#{$antPrefix}-typography {
            font-size: 12px;
          }
        }
      }

      .#{$antPrefix}-divider {
        margin: 8px 0;
      }
    }
  }
}

.#{$prefix}.interview-form-animal-card, .#{$prefix}.other-phone-number-card {
  margin-bottom: 10px;
  box-shadow: none;
}

.#{$prefix}.interview-form-animal-card {
  position: relative;

  .#{$prefix}-head {
    position: absolute;
    width: 100%;
    padding: 4px 0 0;
  }

  .#{$prefix}-body {
    padding-top: 24px;

    .#{$antPrefix}-form-item {
      width: 95%;
    }
  }
}

.#{$prefix}.other-phone-number-card {
  transform: translateX(100%);
  
  .#{$prefix}-body {
    .#{$antPrefix}-flex {
      margin-bottom: 4px;
    }
  }
}