@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
  color: $textColor;
}

.#{$prefix}.underlined {
  border-bottom: 1px solid $primaryColor;
  padding-bottom: 4px;
  width: fit-content;
  margin: 1em 0;
}

.title-wrap {
  white-space: wrap;
}
