@import 'variables';

$prefix: $antPrefix + -carousel;

.#{$prefix} {
    .slick-dots {
        li button {
            background: $primaryColor;   
        }
    }

    .slick-slide {
        display: inline-flex;
        justify-content: center;

        .#{$antPrefix}-image {
            padding: 8px;
            border: 1px solid $borderColor;
        }
    }
}