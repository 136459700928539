@import 'variables';

$prefix: $antPrefix + -list;

.#{$prefix} {
  color: $textColor;
  &-item-meta {
    &-title,
    &-description {
      color: $textColor;
    }
  }
  &-item {
    border-color: $veryLightGray;

    .#{$antPrefix}-typography:first-child {
      color: $dimGray;
      font-weight: 400;
      margin-right: 16px;
    }
  }
  &-header {
    border-color: $primaryColor;

    .#{$antPrefix}-typography {
      font-size: 15px;
    }
  }
}
