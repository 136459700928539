@import 'variables';

$prefix: $antPrefix + -divider;

.#{$prefix} {
  border-color: $borderColor;

  &-horizontal {
    &.#{$prefix} {
      &-with-text-center,
      &-with-text-left,
      &-with-text-right {
        color: $textColor;
      }
    }
  }
}
