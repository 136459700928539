@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
  color: $textColor;
  font-weight: 500;
}

.#{$prefix}.login-title {
  text-align: center;
  color: $dimGray;
  margin-bottom: 32px;
  font-weight: 400;
}

.#{$prefix}.underlined {
  border-bottom: 1px solid $primaryColor;
  padding-bottom: 4px;
  width: fit-content;
  margin-bottom: 1em;
  word-wrap: break-word;
  text-align: center;
}
