@import 'variables';

.gjs-editor {
    .gjs-rte-action {
        min-width: 25px;
        width: fit-content;
    }
}

.gjs-one-bg.sp-palette-buttons-disabled {
    top: 200px!important;
    left: unset !important;
    right: 200px !important;
}