@import 'variables';

$prefix: $antPrefix + -checkbox;

.#{$prefix} {

  &-wrapper,
  & {
    color: $textColor;
  }

  &-wrapper:hover .#{$prefix}-inner,
  &:hover .#{$prefix}-inner,
  &-input:focus+.#{$prefix}-inner {
    border-color: $primaryColor;
  }

  &-inner {
    background-color: $componentBackground;
    border-color: $borderColor;
  }

  &-checked {
    &:after {
      border-color: $primaryColor;
    }

    .#{$prefix}-inner {
      background-color: $primaryColor;
      border-color: $primaryColor;
    }
  }

  &-indeterminate .#{$prefix}-inner {
    background-color: $componentBackground;
    border-color: $borderColor;

    &::after {
      background-color: $primaryColor;
    }
  }

  &-disabled {
    .#{$prefix}-inner {
      background-color: $bodyBackground;
    }

    &+span {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-wrapper-checked {
  &:hover, &:focus {
    .#{$prefix}-checked .#{$prefix}-inner {
      background-color: $secondaryColor !important;
      border-color: $secondaryColor !important;
    }
  }
}