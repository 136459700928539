@import 'variables';

$prefix: $antPrefix + -switch;

.#{$prefix} {
  border-color: $borderColor;
  &-checked {
    background: $primaryColor !important;
  }
}
