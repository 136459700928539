@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}-wrapper {
  .#{$prefix}-drag {
    background: $componentBackground;
    border-color: $borderColor;

    &:hover,
    &:focus {
      border-color: $primaryColor;
      background: $itemActiveBackground;
    }

    .#{$prefix}-drag-container {
      .#{$prefix}-drag-icon .anticon {
        color: $primaryColor;
      }

      .#{$prefix}-drag-icon:last-child {
        margin-bottom: 0;
      }
    }
  }

  .#{$prefix}-drag + .#{$prefix}-list {
    margin-top: 8px;
  }

  .#{$prefix}-list.#{$prefix}-list-picture-card {
    .#{$prefix}-list-item-container {
      width: fit-content;
      height: fit-content;
    }
  }

  .custom-fileList-item {
    border-radius: 4px;
    overflow: hidden;

    .file-image-col {
      min-height: 80px;
      max-height: 100px;

      .#{$antPrefix}-image {
        min-height: 80px;
        max-height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        overflow: hidden;
        object-fit: cover;
  
        .#{$antPrefix}-image-img {
          min-height: 80px;
          height: 100%;
        }
      }
    }

    .fileList-metadata {
      height: 100%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .file-name {
        font-size: 12px;
        color: $textColorSecondary;
        height: fit-content;
      }
    }
  }
}

.file-manager {
  margin: 16px 0;

  .fileList-item {
    .file-name {
      font-size: 13px;
      color: $textColorSecondary;
      word-break: break-all;
    }
  }

  .fileList-item:not(:last-child) {
    margin-bottom: 8px;
  }
}