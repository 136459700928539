@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}-wrapper {
  display: block;

  .#{$prefix}-drag {
    background: $componentBackground;
    border-color: $borderColor;

    &:hover,
    &:focus {
      border-color: $primaryColor;
      background: $itemActiveBackground;
    }

    .#{$prefix}-drag-container {
      .#{$prefix}-drag-icon .anticon {
        color: $primaryColor;
      }

      .#{$prefix}-drag-icon:last-child {
        margin-bottom: 0;
      }
    }
  }

  .#{$prefix}-drag + .#{$prefix}-list {
    margin: 8px 0 4px;
  }

  .#{$prefix}-list.#{$prefix}-list-picture-card {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    .#{$prefix}-list-item-container {
      width: fit-content;
      height: fit-content;

      .#{$prefix}-list-item {
        padding: 4px;
        border-radius: 4px;

        .#{$prefix}-list-item-thumbnail {
          width: auto;
          height: 64px;
          object-fit: contain;

          img {
            border-radius: 2px;
          }
        }
      }
    }
  }

  .custom-fileList-item {
    border-radius: 4px;
    overflow: hidden;

    .file-image-col {
      min-height: 80px;
      max-height: 100px;

      .#{$antPrefix}-image {
        min-height: 80px;
        max-height: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        overflow: hidden;
        object-fit: cover;
  
        .#{$antPrefix}-image-img {
          min-height: 80px;
          height: 100%;
        }
      }
    }

    .fileList-metadata {
      height: 100%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .file-name {
        font-size: 12px;
        color: $textColorSecondary;
        height: fit-content;
      }
    }
  }
}

.file-manager {
  .fileList {
    .fileList-item {
      padding: 6px 0;

      .#{$antPrefix}-list-item-meta {
        align-items: center;

        .#{$antPrefix}-list-item-meta-avatar {
          font-size: 16px;
          line-height: 18px;

          .anticon-check-circle {
            color: $primaryColor !important;
          }
        }
        
        .#{$antPrefix}-list-item-meta-content {
          font-size: 13px;
          font-weight: 700;
          color: $dimGray;
          word-break: break-all;
        }
      }
    }  
  }
}