@import 'variables';

$prefix: $antPrefix + -progress;

.#{$prefix} {
  &-text {
    color: $textColorSecondary;
  }
  &.#{$prefix}-status-success {
    .#{$prefix}-inner:not(.#{$prefix}-circle-gradient) .#{$prefix}-circle-path {
      stroke: $addColor;
    }
  }
  .#{$prefix}-inner:not(.#{$prefix}-circle-gradient) .#{$prefix}-circle-path {
    stroke: $primaryColor;
  }
}

.#{$prefix}-inner {
  background: $contrastBackground;
}
