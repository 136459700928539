@import 'variables';

$prefix: $antPrefix + -radio;

.#{$prefix} {

  &-wrapper,
  & {
    color: $textColor;
  }

  &-wrapper:hover .#{$prefix}-inner,
  &:hover .#{$prefix}-inner,
  &-input:focus+.#{$prefix}-inner {
    border-color: $primaryColor;
  }

  &-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;
  }

  &-checked {
    &:after {
      border-color: $primaryColor;
    }

    .#{$prefix}-inner {
      border-color: $primaryColor;
      background-color: $bodyBackground;

      &::after {
        background-color: $primaryColor;
      }
    }
  }

  &-indeterminate .#{$prefix}-inner {
    background-color: $bodyBackground;
    border-color: $borderColor;

    &::after {
      background-color: $primaryColor;
    }
  }

  &-disabled .#{$prefix}-inner {
    background-color: $bodyBackground;
  }
}

.#{$prefix}-group {
  .#{$prefix}-button-wrapper-checked:not(.#{$prefix}-button-wrapper-disabled) {
    background: $secondaryColor;
    color: $clientColor;
    border-color: $secondaryColor;
    border-radius: 4px;

    &::before {
      display: none;
    }
  }

  .#{$prefix}-button-wrapper:not(.#{$prefix}-button-wrapper-checked):not(.#{$prefix}-button-wrapper-disabled) {
    background: transparent;
    border: 0;

    &:hover {
      color: $secondaryColor;
      background: transparent;
    }

    &::before {
      display: none;
    }
  }
}