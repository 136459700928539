@import 'variables';

$notificationPrefix: $antPrefix + -notification;
$messagePrefix: $antPrefix + -message;

.#{$notificationPrefix} {
  color: $textColor;
  &-notice {
    background-color: $componentBackground;
    border: 1px solid $contrastBackground;
    &-message {
      color: $textColor;
    }
  }

  .#{$messagePrefix} {
    color: $textColor;
    &-notice-content {
      background-color: $componentBackground;
      border: 1px solid $contrastBackground;
    }
  }

  .notification-item {
    width: 100%;
  }
}

.notifications-list {
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
  padding: 5px 5px 5px 5px;
}
