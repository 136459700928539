@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }
  &-head {
    border: 0;
    word-wrap: break-word;
  }
  &-bordered {
    border-color: $borderColor;
  }

  .#{$prefix}-head + .#{$prefix}-body {
    padding: 0 24px 24px;
  }
}

.dashboard-card-wrapper {
  @media (min-width: 1200px) {
    .#{$prefix}.dashboard-card {
      padding: 24px 0;

      .#{$prefix}-body {
        padding: 32px;
      }
    }
  }

  .#{$prefix}.dashboard-card {
    box-shadow: 2px 2px 5px 0px rgba(217, 186, 156, 0.5);
    border-color: $clientColor;
    height: 100%;

    .#{$prefix}-body {
      text-align: justify;

      h4.#{$antPrefix}-typography {
        color: $secondaryColor;
        font-weight: 700;
        margin: 24px 0 0;
      }
    }

    &:hover {
      border-color: $primaryColor;
    }
  }
}

.#{$prefix}.profile-name-card,
.#{$prefix}.my-subscription-card,
.#{$prefix}.vet-card {
  border-color: $primaryColor;
  box-shadow: 5px 5px 0px 0px $primaryColor;
}

.#{$prefix}.profile-name-card {
  border-radius: 24px;
  width: 300px;

  .#{$prefix}-body {
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    h4.#{$antPrefix}-typography {
      margin: 0;
    }

    &::after {
      display: none;
    }

    .#{$prefix}-meta-avatar {
      padding-inline-end: 0;
      padding: 6px;
      border: 1px solid $borderColor;
      border-radius: 100%;
    }
  }

  &::before {
    display: none;
  }
}

.#{$prefix}.ad-list-card,
.supervision-list-card,
.my-subscription-card {
  max-width: 1200px;
  margin: auto;
  white-space: normal;

  p {
    margin: 8px 0 0;
    text-align: justify;
  }
}

.supervision-list-card {
  position: relative;
  max-width: 100%;

  .#{$prefix}-head {
    width: 100%;

    .#{$prefix}-extra {
      .#{$antPrefix}-typography {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }

  .#{$prefix}-body {
    padding: 24px !important;

    .#{$antPrefix}-descriptions-title {
      width: 85%;
      flex: unset;
    }
  }
}

.#{$prefix}.my-subscription-card {
  .#{$prefix}-head {
    padding: 24px 24px 16px;

    .#{$antPrefix}-typography {
      margin-bottom: 0;
    }
  }
}

.ad-list-card {
  gap: 8px;
}

.card-title {
  white-space: normal;
}
