@import 'variables';

$prefix: $antPrefix + -descriptions;

.#{$prefix} {
  &-bordered {
    .#{$prefix} {
      &-view,
      &-row,
      &-item-label,
      &-item-content {
        border-color: $contrastBackground;
      }
      &-item-label {
        background: $contrastBackground;
      }
    }
  }
  &-title,
  &-item-label,
  &-item-content {
    color: $textColor;
  }

  .#{$prefix}-row {
    > th {
      padding-bottom: 0;
    }
  }
}
