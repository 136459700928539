@import "variables";

$prefix: $antPrefix + '-skeleton';

.#{$prefix} {
  .#{$prefix}-content {
    .#{$prefix}-title, .#{$prefix}-paragraph > li {
      background: $textColor;
    }
  }
}

%bgAnimated {
  background-image: linear-gradient(90deg, $textColorSecondary 25%, $bodyBackground 37%, $textColorSecondary 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.#{$prefix}.#{$prefix}-active {
  .#{$prefix}-content {
    .#{$prefix}-title,
    .#{$prefix}-paragraph > li {
      @extend %bgAnimated;
    }
  }

  .#{$prefix}-input {
    @extend %bgAnimated;
  }
}

@keyframes #{$prefix}-loading {
  0% {background-position: 100% 50%}
  100% {background-position: 0 50%;}
}
