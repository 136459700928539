@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;

  >.#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }

  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {

    &-active,
    &:hover,
    &-active .#{$prefix}-tab-btn {
      color: $primaryColor;
    }

    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-card {
  .#{$prefix}-nav .#{$prefix}-nav-list {
    gap: 8px;

    .#{$prefix}-tab {
      border-radius: 6px;
      background: $componentBackground;

      &-active,
      &-active .#{$prefix}-tab-btn {
        color: $clientColor;
        background: $primaryColor;
      }

      &:hover,
      &:hover .#{$prefix}-tab-btn {
        border-color: $primaryColor;
      }
  
      &-disabled,
      &-disabled:hover {
        color: $disabledColor;
      }
    }
  }

  >.#{$prefix}-nav .#{$prefix}-nav-wrap::after, > .#{$prefix}-nav::before {
    display: none;
  }
}