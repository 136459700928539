@import 'variables';

$prefix: $antPrefix + -select;

.#{$prefix}-dropdown {
  background-color: $contrastBackground;

  .#{$prefix}-item {
    &:not(.#{$prefix}-item-option-disabled) {
      color: $textColor;
    }
  }

  .#{$prefix}-item-option-selected:not(.#{$prefix}-item-option-disabled) {
    color: $primaryColor;
    background-color: $itemHoverBackground;

    .#{$prefix}-item-option-state {
      color: $primaryColor;
    }

    &:hover {
      background-color: $itemActiveBackground;
    }
  } 
}

%disabled {
  &.#{$prefix}-disabled {
    .#{$prefix}-selector {
      color: $disabledColor;
      background: $bodyBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-arrow {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-multiple {
  border-radius: 6px;

  &:not(.#{$prefix}-disabled) {
    .#{$prefix}-selector {
      color: $textColor;
      background-color: $componentBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-arrow {
      color: $textColor;
    }

    .#{$prefix}-selection-item {
      background: $itemActiveBackground;
      border-color: $itemActiveBackground;

      .#{$antPrefix}-tag {
        line-height: 20px;
      }
    }

    .#{$prefix}-selection-item-remove {
      color: $borderColor;

      &:hover {
        color: $textColor;
      }
    }

    &.#{$prefix}-open,
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $primaryColor !important;

      .#{$prefix}-selector {
        border-color: $primaryColor !important;
      }

      .#{$prefix}-arrow {
        color: $primaryColor;
      }
    }
  }

  @extend %disabled;
}

.#{$prefix} {
  &.#{$prefix}-single:not(.#{$prefix}-customize-input) {
    border-radius: 6px;

    .#{$prefix}-selector {
      transition: all 0.3s;
    }

    &:not(.#{$prefix}-disabled) {
      .#{$prefix}-selector {
        color: $textColor;
        background-color: $componentBackground;
        border-color: $borderColor;

        .#{$prefix}-selection-item {
          .#{$antPrefix}-tag {
            line-height: 20px;
            vertical-align: middle;
            margin-bottom: 3px;
          }
        }
      }

      .#{$prefix}-arrow {
        color: $textColor;
      }

      &.#{$prefix}-open,
      &:hover,
      &:focus,
      &:focus-within {
        .#{$prefix}-selector {
          color: $primaryColor;
          border-color: $primaryColor;

          .#{$prefix}-selection-item {
            opacity: 1;
          }
        }

        .#{$prefix}-arrow {
          color: $primaryColor;
        }
      }
    }

    @extend %disabled;
  }

  &-item-option-active:not(.#{$prefix}-item-option-disabled) {
    background-color: $itemHoverBackground;
  }
}