@import 'variables';

$prefix: $antPrefix + -badge;

.#{$prefix}.notification-badge {
    .#{$prefix}-count {
        top: 20px;
        inset-inline-end: 3px;
    }

    .#{$antPrefix}-avatar {
        background: $clientColor;
        border-color: $primaryColor;
        color: $primaryColor;
        transition: all 0.3s linear;
    }

    &:hover {
        .#{$antPrefix}-avatar {
            background: $primaryColor;
            border-color: $primaryColor;
            color: $clientColor;
        }
    }
}