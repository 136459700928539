@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header {
    background: $secondaryColor;
  }

  &-content {
    border-radius: 0%;
  }
}

.login-layout {
  min-height: 100vh;
  display: inherit;
  background: url('../../../images/loginanisenior.png');
  background-size: cover;
  padding: 24px 0;

  .login-form-col {
    min-height: calc(100vh - 48px);
    max-height: calc(100vh - 48px);
    overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 80px 80px 0px;
    background: rgba(254, 253, 249, 0.7);
    backdrop-filter: blur(8px);
    overflow-x: hidden;

    form {
      width: 90%;

      .#{$antPrefix}-form-item-label > label, .#{$antPrefix}-checkbox-wrapper {
        color: $darkGray;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px;

    .login-form-col {
      border-radius: 40px;
    }
  }
}

.#{$antPrefix}-page-header + .#{$prefix}-content {
  @media (min-width: 1200px) {
    padding: 24px 10vw !important;
  }
}

.content-custom {
  margin-top: 16px !important;
  border-radius: 10px !important;
}

.#{$antPrefix}-page-header + .#{$prefix}-content.content-custom {
  margin-top: 0 !important;
  border-radius: 0 0 10px 10px !important;
}