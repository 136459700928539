@import 'variables';

$prefix: $antPrefix + -image;

.#{$prefix} {
    padding: 6px;
    border: 1px solid $borderColor;
}

.image-container {
    display: flex;
    margin-bottom: 24px;
    gap: 12px;
    flex-wrap: wrap;

    .sub-images-container {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: flex-start;
    }
}