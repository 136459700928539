@import 'variables';

$prefix: $antPrefix + -pagination;
$disabledClasses: '.#{$prefix}-disabled a, .#{$prefix}-disabled:hover a, .#{$prefix}-disabled:focus a, .#{$prefix}-disabled .#{$prefix}-item-link, .#{$prefix}-disabled:hover .#{$prefix}-item-link, .#{$prefix}-disabled:focus .#{$prefix}-item-link';

.#{$prefix} {
  color: $textColor;

  &-prev,
  &-next,
  &-pagination-prev,
  &-pagination-next,
  &-item {
    border: 0;
    font-size: 16px;

    .#{$prefix}-item-link {
      border: 0;
      background-color: transparent;
      font-size: 16px;
    }

    &:not(.#{$prefix}-disabled) {
      color: $textColor;
      background-color: transparent;
      border-color: $borderColor;

      .#{$prefix}-item-link,
      a {
        border-color: $borderColor;
        color: $textColor;
      }
    }

    &:not(.#{$prefix}-disabled):not(.#{$prefix}-item-active) {

      &:hover {
        border-color: $primaryColor;
        color: $primaryColor;
        background: transparent;

        a {
          border-color: $primaryColor;
          color: $primaryColor;
        }
      }

      .#{$prefix}-item-link,
      a {
        border-color: $borderColor;
        color: $textColor;

        &:hover {
          color: $primaryColor;
          background: transparent;
        }
      }
    }

    &-container,
    .#{$prefix}-item-container {
      .#{$prefix}-item-ellipsis {
        color: $disabledColor;

        a {
          color: $disabledColor;
        }
      }

      .#{$prefix}-item-link-icon {
        color: $primaryColor;
      }
    }
  }

  &-item {
    &-active {
      border-color: $primaryColor;
      cursor: default;

      a {
        color: $primaryColor;
        cursor: default;
      }
    }
  }
}

#{$disabledClasses} {
  color: $disabledColor;
  border-color: $borderColor;
}