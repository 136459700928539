@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor;
  height: fit-content;
  border-color: $primaryColor;
  background-color: $componentBackground;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.05);

  &-borderless {
    background-color: $componentBackground;
    border: 0;
    box-shadow: none;

    .#{$prefix}-item {
      border: 0;
      box-shadow: none;

      .#{$prefix}:not(.#{$prefix}-dashed) {
        padding: 0;
      }
    }

    & > .#{$prefix}-item:not(.#{$prefix}-item-disabled) {
      border: 1px solid $primaryColor;
      border-radius: 4px;
      margin-bottom: 8px;

      > .#{$prefix}-content {
        background: transparent;
        border: 0;
      }
    }

    .#{$prefix}-item-disabled {
      .#{$prefix}-header {
        padding: 12px 0;

        .#{$prefix}-header {
          padding: 8px 4px 8px 16px;
        }
      }
    }
  }

  & > .#{$prefix}-item {
    border-color: $primaryColor;

    > .#{$prefix}-header {
      color: $textColor;
      align-items: center;

      .#{$prefix}-header-text {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  &-content {
    color: $textColor;
    background: $componentBackground;
    border-color: $componentBackground;

    .#{$prefix}-content-box {
      padding: 8px 24px 16px;

      .#{$antPrefix}-layout-content {
        margin: 0 !important;
        padding: 0 !important;
      }

      .#{$antPrefix}-input-group {
        h4.#{$antPrefix}-typography {
          font-size: 16px;
          margin-top: 24px;
          font-weight: 700;
        }

        .#{$antPrefix}-form-item > .#{$antPrefix}-form-item-row {
          > .#{$antPrefix}-form-item-control:first-child:last-child {
            justify-content: end;
            min-width: 100%;
          }
        } 
      }
    }
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0;
}

.#{$prefix}.ratings-collapse {
  width: 100%;

  .#{$prefix}-item {
    border: 0;
  }

  .#{$prefix}-content-box {
    padding: 0 8px;
  }
}