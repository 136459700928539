@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
  border-radius: 0%;
  // padding: 12px 24px 0;
  margin: 16px 16px 0;
  border-radius: 10px 10px 0px 0px;

  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $textColor;
  }

  &-heading-title,
  &-back-button {
    color: $primaryColor;
  }

  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }

  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link,
  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link a {
    &:hover {
      background: transparent;
      color: $primaryColor;
    }
  }

  .#{$prefix}-heading {
    .#{$prefix}-heading-left {
      .#{$prefix}-back .#{$prefix}-back-button {
        &:hover,
        &:focus {
          color: $primaryColor;
        }
      }

      .#{$prefix}-heading-title {
        font-weight: 700;

        .#{$antPrefix}-typography {
          color: $primaryColor;
        }
      }
    }
  }
}
