@import 'variables';

$prefix: $antPrefix + -image;

.supervision-img-wrapper {
    display: flex;
    max-width: 100%;
    gap: 8px;
    flex-wrap: wrap;
    align-items: flex-start;

    .#{$prefix} {
        padding: 6px;
        border: 1px solid $borderColor;
    }
}

.#{$antPrefix}-card img {
    max-height: 320px;
}