@import 'variables';

$prefix: $antPrefix + -switch;

.#{$prefix} {
  border-color: $borderColor;
  &-checked {
    background: $primaryColor !important;
  }
}

.list-switch-wrapper {
  gap: 8px;
  margin-right: 3vw;

  p {
    margin-bottom: 0;
  }
}
