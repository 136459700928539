@import 'variables';

$prefix: $antPrefix + -result;

.#{$prefix} {
  &-title {
    color: $textColor;
  }
  &-subtitle {
    color: $disabledColor;
  }
}
