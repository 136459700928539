@import 'variables';

$prefix: $antPrefix + -alert;

.#{$prefix} {
  color: $textColor;
}

.#{$prefix}-with-description {
  padding: 8px 8px 8px 12px;

  .#{$prefix}-icon {
    font-size: 20px;
    margin-right: 8px;
  }
}

.dashboard-alert {
  margin-bottom: 16px;
}
